
import CategoryRecursive from '@/components/collections/business/CategoryRecursive.vue'
import CategoryWithChildrenModel from 'tradingmate-modules/src/models/api/admin/categories/CategoryWithChildrenModel'
import { Services } from 'tradingmate-modules/src/services'
import { Vue, Component } from 'vue-property-decorator'

@Component({
  components: {
    CategoryRecursive
  }
})

export default class AdminCategoryDetail extends Vue {
  private categoryWithChildren: CategoryWithChildrenModel | null = null;

  mounted (): void {
    this.GetCategoriesWithChildren()
  }

  GetCategoriesWithChildren (): void {
    const categoryId = this.$route.params.id
    if (!categoryId) return
    Services.API.Admin.Categories.GetCategoriesWithChildren(categoryId)
      .then((returnModels) => {
        this.categoryWithChildren = returnModels
      })
  }
}

