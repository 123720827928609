
import CategoryWithChildrenModel from 'tradingmate-modules/src/models/api/admin/categories/CategoryWithChildrenModel'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
  }
})

export default class CategoryRecursive extends Vue {
  @Prop({ required: true })
  private readonly categoryWithChildren!: CategoryWithChildrenModel;
}

